//@ts-check
import React, { useContext, useState } from "react"
import { Link, navigate } from "gatsby"

import { Button, Form } from "antd"
import { Auth } from "aws-amplify"
import { AuthContext } from "../../context/AuthContext"
import { isBrowser } from "../../utils/SSR"

const ResetPassword = () => {
  const [error, setError] = useState("")

  const confirmSubmit = async ({ old_password, new_password }) => {
    try {
      await Auth.currentAuthenticatedUser().then(user => {
        return Auth.changePassword(user, old_password, new_password)
      })

      navigate("/my-account")
    } catch (e) {
      setError("Unable to log in with that email and password")
    }
  }

  const loginSubmitFailed = () => {
    console.log("form submit failed")
  }

  const email =
    (isBrowser() && window.localStorage.getItem("confirmEmail")) || ""

  return (
    <div className="login-form">
      <Form
        name="basic"
        wrapperCol={{ span: 24 }}
        onFinish={confirmSubmit}
        onFinishFailed={loginSubmitFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          className="form-group"
          label="Password"
          name="old_password"
          rules={[
            { required: true, message: "Please input your old password!" },
          ]}
        >
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item
          className="form-group"
          label="Password"
          name="new_password"
          rules={[
            { required: true, message: "Please input your new password!" },
          ]}
        >
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" style={{ height: "50px" }}>
            Set New Password
          </Button>
        </Form.Item>
        {error && <div>{error}</div>}
      </Form>
    </div>
  )
}

export default ResetPassword
