//@ts-check
import React, { useContext } from "react"
import Layout from "../components/App/Layout"
import AuthWrapper from "../components/AuthWrapper"

import Navbar from "../components/LinkedInPowertools/NavbarLIP"
import Footer from "../components/App/Footer"

import ResetPassword from "../components/ProfileAuthentication/ResetPassword"
import { AuthContext } from "../context/AuthContext"
import { navigate } from "gatsby"

const ResetPasswordPage = () => {
  const authContext = useContext(AuthContext)

  if (authContext.isLoggedIn === false) {
    navigate("/profile-authentication")
  }

  return (
    <AuthWrapper>
      <Layout>
        <Navbar />

        <section className="profile-authentication-area ptb-100">
          <div className="container">
            <h2>Forgot Password</h2>

            <div className="row">
              <div className="col-lg-6 col-md-12">
                <ResetPassword />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </Layout>
    </AuthWrapper>
  )
}

export default ResetPasswordPage
